.mainContainer {
  	background-color: #ffffff; 
    /* box-shadow: 5px 5px 10px #c8bcb2, -2px -2px 15px #fffef0; */
    width: 40rem;
    height: 34rem;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-self: center;
    overflow: hidden;
    border-radius: 20px;
}

.selectors {
  margin-top: 0.5rem;
  display: flex;
  justify-content: center;
}

.bottomContainer {
  margin: 4.5%;
  padding: 3%;
  border-radius: 10px;
}

.loopChords {
  display: flex;
  justify-content: center;
  margin-top: 1%;
  margin-bottom: -1%;
}

.loopChords button {
  margin: 2px;
  height: 3rem;
  width: 4rem;
  border: none;
  padding: 9px;
  border-radius: 5px;
  color: #313131;
  background-color: #bbdefb;
  box-shadow: 5px 5px 10px #b2bac8;
}

.loopChords button:hover {
  margin: 2px;
  height: 3rem;
  width: 4rem;
  border: none;
  padding: 9px;
  border-radius: 5px;
  color: #313131;
  background-color: #90caf9;
  box-shadow: 5px 5px 10px #b2bac8;
}

.loopChordNotes {
  display: flex;
  justify-content: center;
  margin-top: 2.5%;
}

.loopChordNotes button {
  margin: 2px;
  margin-bottom: -4%;
  height: 3rem;
  width: 4rem;
  border: none;
  padding: 9px;
  border-radius: 5px;
  color: #313131;
  background-color: #82c3f8;
  box-shadow: 5px 5px 10px #b2bac8;
}

.loopChordNotes button:hover {
  margin: 2px;
  margin-bottom: -4%;
  height: 3rem;
  width: 4rem;
  border: none;
  padding: 9px;
  border-radius: 5px;
  color: #313131;
  background-color: #59aef4;
  box-shadow: 5px 5px 10px #b2bac8;
}

.swapArea {
  display: flex;
  flex-direction: row;
  align-items: start;
  height: 50%;
}

.suggestions {
  display: flex;
  flex-direction: column;
  margin-right: 10%;
  margin-left: 3%;
  padding: 2%;
  height: 35vh;
  border-radius: 10px;
  background-color: #eaeaea; 
  /* box-shadow: 5px 5px 10px #c8bcb2, -2px -2px 15px #fffef0; */
  box-shadow: inset 6px 6px 6px #c7c8c9, inset 3px 4px 4px #868686;
}

.suggestions p {
  margin-top: -2px;
}

.suggestionList {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  scrollbar-width: 10px;
}

/* Width - Chrome */
.suggestionList::-webkit-scrollbar {
  width: 10px;
}

/* Track - Chrome*/
.suggestionList::-webkit-scrollbar-track {
  background: #ffffff;
}

/* Handle - Chrome*/
.suggestionList::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover - Chrome*/
.suggestionList::-webkit-scrollbar-thumb:hover {
  background: #555;
} 

.suggestionOption {
  border: none;
  padding: 9px;
  border-radius: 5px;
  margin-bottom: 4px;
  color: #313131;
  background-color: #bbdefb;
  box-shadow: 2px 1px 1px #b2bac8;
}

.suggestionOption:hover {
  border: none;
  padding: 9px;
  border-radius: 5px;
  margin-bottom: 2px;
  color: #313131;
  background-color: #90caf9;
  box-shadow: 5px 5px 10px #b2bac8;
}

.swapDescription {
  margin-top: 0rem;
  margin-right: 3%;
  height: 26.5vh;
  padding-left: 3%;
  padding-top: 2%;
  padding-bottom: 11%;
  border-radius: 10px;
  background-color: #eaeaea; 
  /* box-shadow: 5px 5px 10px #c8bcb2, -2px -2px 15px #fffef0; */
  box-shadow: inset 6px 6px 6px #c7c8c9, inset 3px 4px 4px #868686;

}

.swapDescription h1 {
  margin-top: -2px;
  font-size: medium;
}

.swapDescription p {
  margin-top: -1px;
}

.swapDescription button {
  border: none;
  padding: 9px;
  border-radius: 5px;
  color: #313131;
  background-color: #b2d2f7;
  box-shadow: 5px 5px 10px #c8bcb2, -2px -2px 15px #fffef0;
}


.swapDescription button:hover {
  border: none;
  padding: 9px;
  border-radius: 5px;
  color: #313131;
  background-color: #a7b9e4;
  box-shadow: inset 5px 5px 5px #e1ba9a, inset 0px 0px 10px #fffef0;
}

.bottomButtons {
  display: flex;
  margin-top: 15%;
}

.refresh {
  margin-top: -2%;
  margin-left: 3%;
  margin-bottom: 2%;
}

.refresh button {
  border: none;
  width: 9.75vw;
  padding: 9px;
  border-radius: 5px;
  color: #ffffff;
  background-color: #42a5f5;
  box-shadow: 5px 5px 10px #c8bcb2, -2px -2px 15px #fffef0;
}

.refresh button:hover {
  border: none;
  padding: 9px;
  border-radius: 5px;
  color: #313131;
  background-color: #8ac1ff;
  box-shadow: inset 5px 5px 5px #7fa9f8, inset 0px 0px 10px #f0f5ff;
}

.confirm {
  display: flex;
  margin-left: 5%;
  margin-top: -2%;
}

.confirm button {
  border: none;
  margin-top: 0%;
  margin-left: 7.5%;
  height: 6.25vh;
  width: 28.25vw;
  padding: 9px;
  border-radius: 5px;
  color: #ffffff;
  background-color: #42a5f5;
  box-shadow: 5px 5px 10px #c8bcb2, -2px -2px 15px #fffef0;
}

.confirm button:hover {
  border: none;
  padding: 9px;
  border-radius: 5px;
  color: #313131;
  background-color: #8ac1ff;
  box-shadow: inset 5px 5px 5px #7fa9f8, inset 0px 0px 10px #f0f5ff;
}
