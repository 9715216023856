body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
    background: #ffffff;
    margin: 0;
}
.NavBar_title__2_ZDb {
    color: rgb(85, 85, 85); 
    margin-top: -0.5rem;
    display: flex;
    justify-content: left;
    margin-left: 5%;
}

@media screen and (max-width: 1000px) {
    .NavBar_title__2_ZDb {
        color: rgb(85, 85, 85); 
        margin-top: -0.5rem;
        display: none;
        justify-content: left;
        margin-left: 5%;
        width: 0wv
    }
}

.NavBar_main__29NTR {
    display: flex;
    margin-top: 2rem; 
    margin-bottom: -1rem;
    align-items: center;
    justify-content: space-between;
    width: 100vw
}

.NavBar_submain__2zCrB {
    display: flex;
    justify-self: right;
}

@media screen and (max-width: 1000px) {
    .NavBar_submain__2zCrB {
        display: flex;
        justify-self: right;
        margin-left: 6.5vw;
    }
}

.NavBar_buttonGroup__3qbNO {
    display: flex;
    flex-direction: row;
}

.NavBar_input__14Xl3 {
    text-align: left;
    justify-content: center;
    margin: 8px;
    height: 20px;
    color: black;
    padding: 0.5rem;
    padding-left: 1.25rem;
    margin-right: 1rem;
    margin-top: 0.5rem;
    width: 18vw;
    border: none;
    border-radius: 10px;
    background: #dde3e6;
    box-shadow: inset 5px 5px 5px #c0c3c6, inset 0px 0px 0px #ebf5fa;
    font-size: 1.25rem;
    font-weight: 300;
    /* pointer-events: none; */
}

.NavBar_input__14Xl3:focus {
    outline: none;
    background: #b6cadb;
    box-shadow: inset 5px 5px 10px #a9b5c8, inset 0px 0px 0px #e5f2ff;
}

.NavBar_forgotPassHolder__iMNqW {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    margin-top: -1.25rem;
    margin-right: 3.5rem;
}

.NavBar_buttonGroup__3qbNO {
    text-align: center;
    justify-content: center;
    margin: 10px;
    margin-right: 10px;
}

.NavBar_loginButton__2XsJp {
    height: 35px;
    margin-right: 15px;
    margin-top: -0.15rem;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 5px;
    border-width: 0;
    background-color: #bbdefb;
    box-shadow: 2px 2px 4px 0.5px rgb(179, 179, 179);
    color: rgb(61, 61, 61); 
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: 400;
}

.NavBar_loginButton__2XsJp:hover {
    height: 35px;
    margin-right: 15px;
    border-radius: 5px;
    border-width: 0;
    margin-top: -0.15rem;
    background-color: #bdd4f6;
    box-shadow: inset 2px 2px 4px 0px #687fad;
}

.NavBar_cancelButton__1nhxk {
    height: 35px;
    margin-right: 15px;
    border-radius: 5px;
    border-width: 0;
    margin-top: -0.15rem;
    background-color: #e0e0e0;
    box-shadow: 3px 4px 5px 0px rgb(179, 179, 179);
}

.NavBar_cancelButton__1nhxk:hover {
    height: 35px;
    margin-right: 15px;
    border-radius: 5px;
    border-width: 0;
    margin-top: -0.15rem;
    background-color: #e0e0e0;
    box-shadow: inset 3px 4px 5px 0px #757575;
}

.NavBar_forgotButton____v3s {
    padding-left: 7px;
    padding-right: 7px;
    height: 35px;
    color: rgb(21, 21, 21); 
    background-color: #90caf9;
    margin-top: -0.15rem;
    border-radius: 5px;
    border-width: 0;
    box-shadow: 3px 4px 5px 0px rgb(170, 170, 179);
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: 400;
}

.NavBar_forgotButton____v3s:hover {
    padding-left: 7px;
    padding-right: 7px;
    height: 35px;
    color: black; 
    border-radius: 5px;
    border-width: 0;
    box-shadow: inset 3px 4px 5px 0px rgb(179, 179, 179);
}

.NavBar_notLoggedIn__3BMS1 {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    margin-top: -1.25rem;
    margin-right: 3.5rem; 
}

.NavBar_errorMessage__1EJj7 {
    margin-right: 125;
    height: 35;
}

.NavBar_welcomeMessage__3CEuY {
    color: white;
    margin-right: 10vw; 
}

.NavBar_loginForm__u_lBH {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 25rem;
    height: 17rem;
    background-color: #ffffff; 
    box-shadow: 5px 5px 10px #b2bcc8, -2px -2px 15px #fffef0;
    border-radius: 15px;
}

.NavBar_signupForm__1doPp {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 17rem;
    height: 20rem;
    background-color: #ffffff; 
    box-shadow: 5px 5px 10px #b2bcc8, -2px -2px 15px #fffef0;
    border-radius: 15px;
}

.NavBar_textField__16qld {
    margin-bottom: 1rem;
}

.NavBar_formButtons__1Z123 {
    margin-top: 0.75rem;
}
.MainPanel_outerContainer__1TMYz {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: center;
}

.MainPanel_mainContainer__1ZWmE {
	display: flex;
	flex-direction: Row;
	border-radius: 20px;
	padding: 2rem;
	margin-top: -2rem; 
	margin-left: 2.5%;
	width: 90%;
	height: 70vh;
	border-radius: 20px; 
	background-color: #ffffff; 
}

.MainPanel_leftPanel__1o-qr {
	padding: 1rem;
	width: 35%;
	height: 74.5vh;
	border-radius: 15px; 
	background: #dcddde; 
	box-shadow: inset 6px 6px 6px #c7c8c9, inset 0px 0px 0px #f6fafc;
}

.MainPanel_rightPanel__1PoZh {
	position: relative;
	margin-left: 2%;
	padding: 0rem;
	width: 60%;
	height: 78.25vh;
	border-radius: 15px; 
	background: #dcddde; 
	box-shadow: inset 6px 6px 10px #c7c8c9, inset 0px 0px 0px #f6fafc;
	overflow: hidden;
}

.MainPanel_loopContainer__2TyL6 {
	padding: 0rem; 
	/* border-radius: 15px;  */
	height: 75vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0;
	margin-top: 3.25vh;
	/* padding-bottom: 2.5vh; */
	
	overflow: auto;
	overflow-x: hidden;
	-ms-overflow-style: none;  /* IE and Edge */
	scrollbar-width: none;  /* Firefox */
}

.MainPanel_loopContainer__2TyL6::-webkit-scrollbar {
    width: 10px;
	/* height: 50vh; */
    border-radius: 2px;
}

.MainPanel_loopContainer__2TyL6::-webkit-scrollbar-track {
    background-color: darkgrey;
    border-radius: 8px;
}

.MainPanel_loopContainer__2TyL6::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	border-radius: 8px;
}

.MainPanel_loopControls__38BtV {
	position: absolute; 
	/* bottom: 0; */
	background-color: rgb(179, 219, 255);
	height: 7vh;
	margin: 0;
	width: 100%;
	/* margin-bottom: 8vh; */
}

.MainPanel_projectInfo__3s7CO {
	background-color: white;
	border-radius: 0.75rem;
	height: 26vh;
	box-shadow: 2px 3px 4px 0px rgb(179, 179, 179);
	padding-left: 10%;
}

.MainPanel_projectControlButtons__2Gk-A {
	float: right;
	margin: 2px 3%;
}

.MainPanel_projectTitleEditButtons__1IfTt {
	float: right;
	margin: 10px 5%;
}

.MainPanel_keyAndMode__2bE8E {
	background-color: white;
	border-radius: 0.75rem;
	height: 22vh;
	/* width: 25vw; */
	box-shadow: 2px 3px 4px 0px rgb(179, 179, 179);
	margin-top: 0.75rem;
}

.MainPanel_bpmControls__1jzJ_ {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.MainPanel_bpmSlider__1v7zj {
	margin-left: 5%;
	margin-right: 3%;
	width: 100%;
}

.MainPanel_bpmInput__-Du4c {
	margin-right: 5%;
	margin-left: 3%;
	width: 70px;
}

.MainPanel_signInTypeography__3ajGF {
	margin-top: 5%;
}
.Loop_chord__1GVjT {
    background-color: white;
    width: 2rem;
    border-radius: 0.5rem;
}

.Loop_loop__3CYoD {
    display: flex;
    justify-content: center;
    margin-top: 1.5%;
    /* align-items: center; */
    width: 100%
}

.Loop_chordButton__3sIVt{
    background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;

    color: #313131;
    background-color: #90caf9;
    box-shadow: 5px 5px 10px #b2bec8;
    border-radius: 10px;
    margin: 1rem;
    margin-top: 1.5vh;
    padding: 1rem;
    height: 10vh;
    width: 15%;
    
}

.Loop_chordButton__3sIVt:hover {
    background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;

    color: #313131;
    background-color: #42a5f5;
    box-shadow: 5px 5px 10px #b2bec8;
    border-radius: 10px;
    margin: 1rem;
    margin-top: 1.5vh;
    padding: 1rem;
    height: 10vh;
    width: 15%;
    
}

.Loop_loopContainer__23G3X {
    /* background-color: #fad9c3; */
    background-color: white;
    width: 90%;
    border-radius: 1rem;
    height: 15vh;
    box-shadow: 0px 0px 10px #b2bcc8;
    /* box-shadow: 5px 5px 8px #c8bcb2, -3px -3px 10px #fff7e4; */
    margin-top: 0vh;
    margin-left: 2.25vw;
    margin-bottom: 0vh;
    overflow: hidden;
    /* display: flex;
    flex-direction: row; */
    justify-content: center;
    align-items: center;
}

.Loop_modal__17Lf9 {
    width: 100%;
    background-color: rgba(214, 228, 243, 0.801);
    display:'flex';
    align-items:'center';
    justify-content:'center';
}
.ProgressionEditor_mainContainer__3GxvP {
  	background-color: #ffffff; 
    /* box-shadow: 5px 5px 10px #c8bcb2, -2px -2px 15px #fffef0; */
    width: 40rem;
    height: 34rem;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-self: center;
    overflow: hidden;
    border-radius: 20px;
}

.ProgressionEditor_selectors__3Fnyy {
  margin-top: 0.5rem;
  display: flex;
  justify-content: center;
}

.ProgressionEditor_bottomContainer__2waaO {
  margin: 4.5%;
  padding: 3%;
  border-radius: 10px;
}

.ProgressionEditor_loopChords__e880f {
  display: flex;
  justify-content: center;
  margin-top: 1%;
  margin-bottom: -1%;
}

.ProgressionEditor_loopChords__e880f button {
  margin: 2px;
  height: 3rem;
  width: 4rem;
  border: none;
  padding: 9px;
  border-radius: 5px;
  color: #313131;
  background-color: #bbdefb;
  box-shadow: 5px 5px 10px #b2bac8;
}

.ProgressionEditor_loopChords__e880f button:hover {
  margin: 2px;
  height: 3rem;
  width: 4rem;
  border: none;
  padding: 9px;
  border-radius: 5px;
  color: #313131;
  background-color: #90caf9;
  box-shadow: 5px 5px 10px #b2bac8;
}

.ProgressionEditor_loopChordNotes__14I8q {
  display: flex;
  justify-content: center;
  margin-top: 2.5%;
}

.ProgressionEditor_loopChordNotes__14I8q button {
  margin: 2px;
  margin-bottom: -4%;
  height: 3rem;
  width: 4rem;
  border: none;
  padding: 9px;
  border-radius: 5px;
  color: #313131;
  background-color: #82c3f8;
  box-shadow: 5px 5px 10px #b2bac8;
}

.ProgressionEditor_loopChordNotes__14I8q button:hover {
  margin: 2px;
  margin-bottom: -4%;
  height: 3rem;
  width: 4rem;
  border: none;
  padding: 9px;
  border-radius: 5px;
  color: #313131;
  background-color: #59aef4;
  box-shadow: 5px 5px 10px #b2bac8;
}

.ProgressionEditor_swapArea__3WTcC {
  display: flex;
  flex-direction: row;
  align-items: start;
  height: 50%;
}

.ProgressionEditor_suggestions__2UyTm {
  display: flex;
  flex-direction: column;
  margin-right: 10%;
  margin-left: 3%;
  padding: 2%;
  height: 35vh;
  border-radius: 10px;
  background-color: #eaeaea; 
  /* box-shadow: 5px 5px 10px #c8bcb2, -2px -2px 15px #fffef0; */
  box-shadow: inset 6px 6px 6px #c7c8c9, inset 3px 4px 4px #868686;
}

.ProgressionEditor_suggestions__2UyTm p {
  margin-top: -2px;
}

.ProgressionEditor_suggestionList__2WEtz {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  scrollbar-width: 10px;
}

/* Width - Chrome */
.ProgressionEditor_suggestionList__2WEtz::-webkit-scrollbar {
  width: 10px;
}

/* Track - Chrome*/
.ProgressionEditor_suggestionList__2WEtz::-webkit-scrollbar-track {
  background: #ffffff;
}

/* Handle - Chrome*/
.ProgressionEditor_suggestionList__2WEtz::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover - Chrome*/
.ProgressionEditor_suggestionList__2WEtz::-webkit-scrollbar-thumb:hover {
  background: #555;
} 

.ProgressionEditor_suggestionOption__1oAD- {
  border: none;
  padding: 9px;
  border-radius: 5px;
  margin-bottom: 4px;
  color: #313131;
  background-color: #bbdefb;
  box-shadow: 2px 1px 1px #b2bac8;
}

.ProgressionEditor_suggestionOption__1oAD-:hover {
  border: none;
  padding: 9px;
  border-radius: 5px;
  margin-bottom: 2px;
  color: #313131;
  background-color: #90caf9;
  box-shadow: 5px 5px 10px #b2bac8;
}

.ProgressionEditor_swapDescription__1mVNV {
  margin-top: 0rem;
  margin-right: 3%;
  height: 26.5vh;
  padding-left: 3%;
  padding-top: 2%;
  padding-bottom: 11%;
  border-radius: 10px;
  background-color: #eaeaea; 
  /* box-shadow: 5px 5px 10px #c8bcb2, -2px -2px 15px #fffef0; */
  box-shadow: inset 6px 6px 6px #c7c8c9, inset 3px 4px 4px #868686;

}

.ProgressionEditor_swapDescription__1mVNV h1 {
  margin-top: -2px;
  font-size: medium;
}

.ProgressionEditor_swapDescription__1mVNV p {
  margin-top: -1px;
}

.ProgressionEditor_swapDescription__1mVNV button {
  border: none;
  padding: 9px;
  border-radius: 5px;
  color: #313131;
  background-color: #b2d2f7;
  box-shadow: 5px 5px 10px #c8bcb2, -2px -2px 15px #fffef0;
}


.ProgressionEditor_swapDescription__1mVNV button:hover {
  border: none;
  padding: 9px;
  border-radius: 5px;
  color: #313131;
  background-color: #a7b9e4;
  box-shadow: inset 5px 5px 5px #e1ba9a, inset 0px 0px 10px #fffef0;
}

.ProgressionEditor_bottomButtons__1mZBt {
  display: flex;
  margin-top: 15%;
}

.ProgressionEditor_refresh__1Oy_P {
  margin-top: -2%;
  margin-left: 3%;
  margin-bottom: 2%;
}

.ProgressionEditor_refresh__1Oy_P button {
  border: none;
  width: 9.75vw;
  padding: 9px;
  border-radius: 5px;
  color: #ffffff;
  background-color: #42a5f5;
  box-shadow: 5px 5px 10px #c8bcb2, -2px -2px 15px #fffef0;
}

.ProgressionEditor_refresh__1Oy_P button:hover {
  border: none;
  padding: 9px;
  border-radius: 5px;
  color: #313131;
  background-color: #8ac1ff;
  box-shadow: inset 5px 5px 5px #7fa9f8, inset 0px 0px 10px #f0f5ff;
}

.ProgressionEditor_confirm__3NP_I {
  display: flex;
  margin-left: 5%;
  margin-top: -2%;
}

.ProgressionEditor_confirm__3NP_I button {
  border: none;
  margin-top: 0%;
  margin-left: 7.5%;
  height: 6.25vh;
  width: 28.25vw;
  padding: 9px;
  border-radius: 5px;
  color: #ffffff;
  background-color: #42a5f5;
  box-shadow: 5px 5px 10px #c8bcb2, -2px -2px 15px #fffef0;
}

.ProgressionEditor_confirm__3NP_I button:hover {
  border: none;
  padding: 9px;
  border-radius: 5px;
  color: #313131;
  background-color: #8ac1ff;
  box-shadow: inset 5px 5px 5px #7fa9f8, inset 0px 0px 10px #f0f5ff;
}

