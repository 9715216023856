.outerContainer {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: center;
}

.mainContainer {
	display: flex;
	flex-direction: Row;
	border-radius: 20px;
	padding: 2rem;
	margin-top: -2rem; 
	margin-left: 2.5%;
	width: 90%;
	height: 70vh;
	border-radius: 20px; 
	background-color: #ffffff; 
}

.leftPanel {
	padding: 1rem;
	width: 35%;
	height: 74.5vh;
	border-radius: 15px; 
	background: #dcddde; 
	box-shadow: inset 6px 6px 6px #c7c8c9, inset 0px 0px 0px #f6fafc;
}

.rightPanel {
	position: relative;
	margin-left: 2%;
	padding: 0rem;
	width: 60%;
	height: 78.25vh;
	border-radius: 15px; 
	background: #dcddde; 
	box-shadow: inset 6px 6px 10px #c7c8c9, inset 0px 0px 0px #f6fafc;
	overflow: hidden;
}

.loopContainer {
	padding: 0rem; 
	/* border-radius: 15px;  */
	height: 75vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0;
	margin-top: 3.25vh;
	/* padding-bottom: 2.5vh; */
	
	overflow: auto;
	overflow-x: hidden;
	-ms-overflow-style: none;  /* IE and Edge */
	scrollbar-width: none;  /* Firefox */
}

.loopContainer::-webkit-scrollbar {
    width: 10px;
	/* height: 50vh; */
    border-radius: 2px;
}

.loopContainer::-webkit-scrollbar-track {
    background-color: darkgrey;
    border-radius: 8px;
}

.loopContainer::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	border-radius: 8px;
}

.loopControls {
	position: absolute; 
	/* bottom: 0; */
	background-color: rgb(179, 219, 255);
	height: 7vh;
	margin: 0;
	width: 100%;
	/* margin-bottom: 8vh; */
}

.projectInfo {
	background-color: white;
	border-radius: 0.75rem;
	height: 26vh;
	box-shadow: 2px 3px 4px 0px rgb(179, 179, 179);
	padding-left: 10%;
}

.projectControlButtons {
	float: right;
	margin: 2px 3%;
}

.projectTitleEditButtons {
	float: right;
	margin: 10px 5%;
}

.keyAndMode {
	background-color: white;
	border-radius: 0.75rem;
	height: 22vh;
	/* width: 25vw; */
	box-shadow: 2px 3px 4px 0px rgb(179, 179, 179);
	margin-top: 0.75rem;
}

.bpmControls {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.bpmSlider {
	margin-left: 5%;
	margin-right: 3%;
	width: 100%;
}

.bpmInput {
	margin-right: 5%;
	margin-left: 3%;
	width: 70px;
}

.signInTypeography {
	margin-top: 5%;
}