.title {
    color: rgb(85, 85, 85); 
    margin-top: -0.5rem;
    display: flex;
    justify-content: left;
    margin-left: 5%;
}

@media screen and (max-width: 1000px) {
    .title {
        color: rgb(85, 85, 85); 
        margin-top: -0.5rem;
        display: none;
        justify-content: left;
        margin-left: 5%;
        width: 0wv
    }
}

.main {
    display: flex;
    margin-top: 2rem; 
    margin-bottom: -1rem;
    align-items: center;
    justify-content: space-between;
    width: 100vw
}

.submain {
    display: flex;
    justify-self: right;
}

@media screen and (max-width: 1000px) {
    .submain {
        display: flex;
        justify-self: right;
        margin-left: 6.5vw;
    }
}

.buttonGroup {
    display: flex;
    flex-direction: row;
}

.input {
    text-align: left;
    justify-content: center;
    margin: 8px;
    height: 20px;
    color: black;
    padding: 0.5rem;
    padding-left: 1.25rem;
    margin-right: 1rem;
    margin-top: 0.5rem;
    width: 18vw;
    border: none;
    border-radius: 10px;
    background: #dde3e6;
    box-shadow: inset 5px 5px 5px #c0c3c6, inset 0px 0px 0px #ebf5fa;
    font-size: 1.25rem;
    font-weight: 300;
    /* pointer-events: none; */
}

.input:focus {
    outline: none;
    background: #b6cadb;
    box-shadow: inset 5px 5px 10px #a9b5c8, inset 0px 0px 0px #e5f2ff;
}

.forgotPassHolder {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    margin-top: -1.25rem;
    margin-right: 3.5rem;
}

.buttonGroup {
    text-align: center;
    justify-content: center;
    margin: 10px;
    margin-right: 10px;
}

.loginButton {
    height: 35px;
    margin-right: 15px;
    margin-top: -0.15rem;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 5px;
    border-width: 0;
    background-color: #bbdefb;
    box-shadow: 2px 2px 4px 0.5px rgb(179, 179, 179);
    color: rgb(61, 61, 61); 
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: 400;
}

.loginButton:hover {
    height: 35px;
    margin-right: 15px;
    border-radius: 5px;
    border-width: 0;
    margin-top: -0.15rem;
    background-color: #bdd4f6;
    box-shadow: inset 2px 2px 4px 0px #687fad;
}

.cancelButton {
    height: 35px;
    margin-right: 15px;
    border-radius: 5px;
    border-width: 0;
    margin-top: -0.15rem;
    background-color: #e0e0e0;
    box-shadow: 3px 4px 5px 0px rgb(179, 179, 179);
}

.cancelButton:hover {
    height: 35px;
    margin-right: 15px;
    border-radius: 5px;
    border-width: 0;
    margin-top: -0.15rem;
    background-color: #e0e0e0;
    box-shadow: inset 3px 4px 5px 0px #757575;
}

.forgotButton {
    padding-left: 7px;
    padding-right: 7px;
    height: 35px;
    color: rgb(21, 21, 21); 
    background-color: #90caf9;
    margin-top: -0.15rem;
    border-radius: 5px;
    border-width: 0;
    box-shadow: 3px 4px 5px 0px rgb(170, 170, 179);
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: 400;
}

.forgotButton:hover {
    padding-left: 7px;
    padding-right: 7px;
    height: 35px;
    color: black; 
    border-radius: 5px;
    border-width: 0;
    box-shadow: inset 3px 4px 5px 0px rgb(179, 179, 179);
}

.notLoggedIn {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    margin-top: -1.25rem;
    margin-right: 3.5rem; 
}

.errorMessage {
    margin-right: 125;
    height: 35;
}

.welcomeMessage {
    color: white;
    margin-right: 10vw; 
}

.loginForm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 25rem;
    height: 17rem;
    background-color: #ffffff; 
    box-shadow: 5px 5px 10px #b2bcc8, -2px -2px 15px #fffef0;
    border-radius: 15px;
}

.signupForm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 17rem;
    height: 20rem;
    background-color: #ffffff; 
    box-shadow: 5px 5px 10px #b2bcc8, -2px -2px 15px #fffef0;
    border-radius: 15px;
}

.textField {
    margin-bottom: 1rem;
}

.formButtons {
    margin-top: 0.75rem;
}