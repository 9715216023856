.chord {
    background-color: white;
    width: 2rem;
    border-radius: 0.5rem;
}

.loop {
    display: flex;
    justify-content: center;
    margin-top: 1.5%;
    /* align-items: center; */
    width: 100%
}

.chordButton{
    background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;

    color: #313131;
    background-color: #90caf9;
    box-shadow: 5px 5px 10px #b2bec8;
    border-radius: 10px;
    margin: 1rem;
    margin-top: 1.5vh;
    padding: 1rem;
    height: 10vh;
    width: 15%;
    
}

.chordButton:hover {
    background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;

    color: #313131;
    background-color: #42a5f5;
    box-shadow: 5px 5px 10px #b2bec8;
    border-radius: 10px;
    margin: 1rem;
    margin-top: 1.5vh;
    padding: 1rem;
    height: 10vh;
    width: 15%;
    
}

.loopContainer {
    /* background-color: #fad9c3; */
    background-color: white;
    width: 90%;
    border-radius: 1rem;
    height: 15vh;
    box-shadow: 0px 0px 10px #b2bcc8;
    /* box-shadow: 5px 5px 8px #c8bcb2, -3px -3px 10px #fff7e4; */
    margin-top: 0vh;
    margin-left: 2.25vw;
    margin-bottom: 0vh;
    overflow: hidden;
    /* display: flex;
    flex-direction: row; */
    justify-content: center;
    align-items: center;
}

.modal {
    width: 100%;
    background-color: rgba(214, 228, 243, 0.801);
    display:'flex';
    align-items:'center';
    justify-content:'center';
}